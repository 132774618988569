import React, { useEffect, useState } from 'react';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import Page from '@components/page';
import VideoPlayer from '@components/video-player';
import IntroModal from '@components/intro-modal';
import Assets from '@assets';
import DATA from '@data';

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Fly = () => {
  const pageTitle = 'Fly';

  const [ step, setStep ] = useQueryParam('step', NumberParam);
  const [ completedSteps, setCompletedSteps ] = useQueryParam('completed', StringParam);
  const [ pageSubTitle, setPageSubtitle ] = useState(null);
  const [ currentVideo, setCurrentVideo ] = useState(null);
  const [ currentVideoTranscript, setCurrentVideoTrasncript ] = useState(null);
  const [ currentVideoThumbnail, setCurrentVideoThumbnail ] = useState(null);
  const [ showLeftNavigation, setShowLeftNavigation ] = useState(false);
  const [ leftNavigationActive, setLeftNavigationActive ] = useState(false);
  const [ rightNavigationActive, setRightNavigationActive ] = useState(false);
  const [ showIntroModal, setShowIntroModal ] = useState(false);
  const [ autoplay, setAutoplay ] = useState(false);

  useEffect(
    () => {
      function loadVideoTrack() {
        const stepData = DATA.FLY.find(s => s.step === step);
        setPageSubtitle(stepData.title);
        setCurrentVideo(stepData.file);
        setCurrentVideoThumbnail(stepData.thumbnail);
        setCurrentVideoTrasncript(stepData.transcript);
      }

      if (localStorage.getItem('session-id')) {
        const flyIntro = window.localStorage.getItem('fly-intro');
        if (!flyIntro || flyIntro != 1) {
          setShowIntroModal(true);
        } else {
          setAutoplay(!iOS());
        }

        let steps = window.localStorage.getItem('completed-steps');
        if (steps) {
          steps = JSON.parse(steps);

          if (steps.fly === true) {
            setShowIntroModal(false);
          }
        }

        if (step) {
          loadVideoTrack();

          if (step === 1) {
            setShowLeftNavigation(false);
            setLeftNavigationActive(false);
          } else {
            setShowLeftNavigation(true);
            setLeftNavigationActive(true);
          }

          if (completedSteps && completedSteps.includes(step)) {
            setRightNavigationActive(true);
          } else {
            setRightNavigationActive(false);
          }
        } else {
          navigate('/fly?step=1');
        }
      } else {
        navigate('/intro');
      }
    },
    [ step ]
  );

  const handleOnLeftNavigationPress = () => {
    const nextStep = step - 1;
    if (nextStep >= 1) {
      setStep(nextStep);
    }
  };

  const handleOnRightNavigationPress = () => {
    setCompletedSteps(completedSteps ? `${completedSteps},${step}` : step);

    if (step === 4) {
      let completedSteps = window.localStorage.getItem('completed-steps');
      if (completedSteps) {
        completedSteps = JSON.parse(completedSteps);
        completedSteps.fly = true;
      }
      window.localStorage.setItem('completed-steps', JSON.stringify(completedSteps));

      navigate('/glide');
    }

    const nextStep = step + 1;
    if (nextStep <= 4) {
      setStep(nextStep);
    }
  };

  const handleVideoPlayerOnFinish = () => {
    if (!rightNavigationActive) {
      setRightNavigationActive(true);
    }

    ReactGA.event({
      category: 'FLY',
      action: `User viewed "Step: ${step}" video`,
    });
  };

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);
    setAutoplay(!iOS());

    window.localStorage.setItem('fly-intro', 1);
  };

  return (
    <React.Fragment>
      <Page
        title={pageTitle}
        subTitle={pageSubTitle}
        icon={Assets.images.PageIconFly}
        audio={Assets.audios.PageAudioFly}
        withNavigation
        withFloatNavigation
        leftNavigation={showLeftNavigation}
        leftNavigationActive={leftNavigationActive}
        leftNavigationText="Back"
        onLeftNavigationPress={handleOnLeftNavigationPress}
        rightNavigationActive={rightNavigationActive}
        rightNavigationText="Next"
        onRightNavigationPress={handleOnRightNavigationPress}
      >
        <VideoPlayer
          file={currentVideo}
          transcript={currentVideoTranscript}
          onFinish={handleVideoPlayerOnFinish}
          thumbnail={currentVideoThumbnail}
          autoplay={autoplay}
        />
      </Page>
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconFly}
          audio={Assets.audios.PageAudioFly}
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Fly;
